<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">members management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim
          eius tempore natus! Neque dolore explicabo aliquid inventore
          consequuntur vitae illum ratione doloribus quo, cum in, soluta
          perferendis laboriosam qui.
        </div>
      </v-row>
    </v-col>

    <div class="mt-3">
      <div class="d-flex">
        <v-dialog width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs"
              ><v-icon>mdi-plus</v-icon>new member</v-btn
            >
          </template>

          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">new member</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-form>
                <v-text-field
                  label="Name"
                  outlined
                  dense
                  v-model="addName"
                ></v-text-field>
                <v-text-field
                  label="Username"
                  outlined
                  dense
                  v-model="addUserName"
                ></v-text-field>
                <v-autocomplete
                  label="Role"
                  :items="['Member', 'Clerk', 'Finance', 'Human Resource']"
                  outlined
                  dense
                  v-model="addRole"
                ></v-autocomplete>
                <v-autocomplete
                  label="Commitee"
                  outlined
                  dense
                  v-model="addCommitee"
                ></v-autocomplete>
                <v-text-field
                  label="Password"
                  outlined
                  dense
                  v-model="addPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn block color="primary"> create member </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          outlined
          dense
          label="Search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="items"
        class="text-capitalize elevation-10 mt-2"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn color="primary" icon @click="editItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn color="primary" icon>
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="editDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">edit member</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-2">
            <v-text-field
              label="Name"
              outlined
              dense
              v-model="editName"
            ></v-text-field>
            <v-text-field
              label="Username"
              outlined
              dense
              v-model="editUserName"
            ></v-text-field>
            <v-autocomplete
              label="Role"
              :items="['Member', 'Clerk', 'Finance', 'Human Resource']"
              outlined
              dense
              v-model="editRole"
            ></v-autocomplete>
            <v-autocomplete
              label="Commitee"
              outlined
              dense
              v-model="editCommitee"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="editDialog = false"> cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary"> save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- delete dialog -->

      <v-dialog v-model="deleteDialog" width="auto">
        <v-card>
          <v-card-title>
            <p class="mx-auto text-uppercase">delete member</p>
          </v-card-title>
          <v-card-text>
            <p class="text-center text-subtitle-1">
              Are you sure you want to delete this item?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="deleteDialog = false">
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error"> delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addName: "",
      addUserName: "",
      addRole: "",
      addPassword: "",
      addCommitee: "",
      editName: "",
      editUserName: "",
      editRole: "",
      editCommitee: "",
      editDialog: false,
      deleteDialog: false,
      search: "",
      headers: [
        {
          text: "name",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "username",
          value: "username",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "commitee",
          class: "text-uppercase black--text",
        },
        {
          text: "role",
          value: "role",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          name: "David Kimani",
          username: "davidkimani",
          commitee: "Budget",
          role: "member",
        },
        {
          name: "David Kimani",
          username: "davidkimani",
          commitee: "Budget",
          role: "member",
        },
        {
          name: "David Kimani",
          username: "davidkimani",
          commitee: "Budget",
          role: "member",
        },
        {
          name: "David Kimani",
          username: "davidkimani",
          commitee: "Budget",
          role: "member",
        },
        {
          name: "David Kimani",
          username: "davidkimani",
          commitee: "Budget",
          role: "member",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.editName = item.name;
      this.editRole = item.role;
      this.editCommitee = item.commitee;
      this.editUserName = item.username;

      this.editDialog = true;
    },
  },
};
</script>

<style></style>
